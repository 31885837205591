import { useEffect, useState } from 'react';

import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { AlphaFullPageBannerSection } from '@netfront/ui-library';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

import { PageLayout, BUTTON_CLASSES, ClientsBanner, ContactForm, LinkButton, YouTubeVideo } from '../components';
import { useToast, useSendContactForm } from '../hooks';

const Home: NextPage = () => {
  const { isDomainReady } = useDomain();
  const { getRegistrationUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });
  const { push } = useRouter();
  const { handleToastError } = useToast();

  const [registrationUrl, setRegistrationUrl] = useState<string>();

  const { handleSendContactForm, isLoading = false } = useSendContactForm({
    onCompleted: ({ isCompleted }) => {
      if (isCompleted) {
        push(`/thank-you`).catch((error) => {
          handleToastError({
            error,
          });
        });

        return;
      }

      const {
        MESSAGES: {
          ERROR: { UNEXPECTED },
        },
      } = COMMON_LIBRARY_CONSTANTS;

      handleToastError({
        error: new Error(UNEXPECTED),
        shouldUseFriendlyErrorMessage: true,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setRegistrationUrl(getRegistrationUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  return (
    <PageLayout
      meta={{
        description: 'Kanzi provides an instant text-to-speech, translation and page to PDF service using a simple user-configured plugin',
        metaTitle: 'Kanzi customisable text-to-speech, translate and PDF plugin',
      }}
      title="Text to speech and translation"
    >
      <AlphaFullPageBannerSection
        backgroundImage="/images/background-whole.svg"
        description="Our accessibility tool provides an easy, accessible drag and drop tool to allow you to communicate with the world"
        image="/images/hero-banner-image.svg"
        title="Instant page translation and text to speech"
      >
        <div className="flex items-center">
          <LinkButton additionalClassNames={`${BUTTON_CLASSES.green} mr-4`} text="Learn more" url="#learn-more" />
          {registrationUrl && <LinkButton additionalClassNames={BUTTON_CLASSES.white} text="Try now for free" url={registrationUrl} />}
        </div>
      </AlphaFullPageBannerSection>

      <section className="bg-white" id="learn-more">
        <div className="c-container py-8 md:py-32">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full mb-6 md:mb-0 md:w-1/3 p-4 text-center">
              <img alt="Configure screen" className="max-w-3/5 md:max-w-full" loading="lazy" src="/images/speech-laptop-icon.svg" />
            </div>
            <div className="w-full md:w-2/3 p-4">
              <p className="text-uppercase weight-500 mb-6">Control, configure and implement your tool in minutes</p>
              <h2 className="font-header">Easy to configure &amp; use</h2>
              <p>
                Kanzi&rsquo;s customisable plugin provides a quick and easy way to add text to speech and/or text translate functionality to
                any online project.
              </p>
              <p className="mb-12">
                Once registered you can configure and manage multiple projects. Implement customised tools securely and flexible according
                to your projects exact needs. Let us help you convert your online content to be accessible, giving all of your valuable
                users access to understand and engage with your content.
              </p>
              {registrationUrl ? (
                <LinkButton additionalClassNames={BUTTON_CLASSES.green} text="Sign up for a free account" url={registrationUrl} />
              ) : null}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-burgandy-100 py-24">
        <div className="c-container c-container--small text-center">
          <h2 className="font-header mb-12">Learn more about Kanzi</h2>
          <YouTubeVideo src="https://www.youtube.com/embed/aWHTtvKEzb0" />
        </div>
      </section>

      <section className="bg-gradient-wave bg-gradient-wave--minimal py-16 md:py-32 relative">
        <div className="c-container color-white">
          <p className="text-uppercase">Tool customisation</p>
          <h2 className="font-header">Flexibility</h2>
          <div className="flex flex-col md:flex-row mb-6 md:mb-0">
            <ul className="pl-6 mb-0 mr-8">
              <li className="ml-0">Build custom players</li>
              <li className="ml-0">Instant text to speech</li>
              <li className="ml-0">Customise highlight and underline</li>
            </ul>
            <ul className="pl-6 mb-0">
              <li className="ml-0">Instant one click translate</li>
              <li className="ml-0">Volume control</li>
              <li className="ml-0">Customisable player theme</li>
            </ul>
          </div>

          <img alt="Customise your Kanzi tool" className="c-ui-graphic" loading="lazy" src="/images/ui-graphic.svg" />
        </div>
      </section>

      <section className="bg-white py-8 md:py-32">
        <div className="c-container">
          <div className="flex flex-wrap -mx-12">
            <div className="w-full md:w-1/3 py-4 px-12 md:py-12">
              <h3 className="h2 font-header">
                Speech &amp; <br /> translate
              </h3>
              <p>
                Instant page translation and text to speech option from the one easy to use mobile friendly customisable tool. Translated
                content is spoken in a language appropriate voice.
              </p>
            </div>
            <div className="w-full md:w-1/3 py-4 px-12 md:py-12">
              <h3 className="h2 font-header">
                Pricing &amp; <br /> reports
              </h3>
              <p>
                Friendly tiered pricing with a free tier for low usage. Pay for what you use and see real time updates of usage. Full usage
                tracking included and added to your monthly invoice.
              </p>
            </div>
            <div className="w-full md:w-1/3 py-4 px-12 md:py-12">
              <h3 className="h2 font-header">
                Projects &amp; <br /> security
              </h3>
              <p>
                Restrict access to your Kanzi player to selected domains using URL restrictions. Set up multiple projects to track and bill
                independent of the project.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-wave bg-gradient-wave--minimal py-32">
        <div className="c-container text-center color-white">
          <h2 className="font-header">Get started today</h2>
          <p className="mb-8 h4">It&#39;s easy to get set up, try the tool today for free!</p>
          {registrationUrl ? (
            <LinkButton additionalClassNames={BUTTON_CLASSES.green} text="Sign up now for free" url={registrationUrl} />
          ) : null}
        </div>
      </section>

      <section>
        <div className="c-container py-20">
          <div className="text-center mb-16">
            <h3 className="h2 font-header">Do you have any questions?</h3>
            <p>Get in touch with the Kanzi team using the contact form below.</p>
          </div>
          <div className="max-w-lg mx-auto">
            <ContactForm
              isSubmitting={isLoading}
              onSend={({ email, firstName, lastName, message: value, organisationName, phoneNumber }) =>
                handleSendContactForm({
                  email,
                  firstName,
                  lastName,
                  organisationName,
                  phoneNumber,
                  value,
                })
              }
            />
          </div>
        </div>
      </section>

      <ClientsBanner />
    </PageLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default Home;
